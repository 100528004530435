



















































import { Component, Vue } from 'vue-property-decorator'
import AppNavDrawer from '@/components/App/AppNavDrawer.vue'
import axiosInstance from '@/plugins/axios'

@Component({
  components: {
    AppNavDrawer
  }
})
export default class ProductsView extends Vue {
  isLoading = false

  headers = [
    { text: 'ຮູບສິນຄ້າ', value: 'picture' },
    { text: 'ຊື່ສິນຄ້າ', value: 'name' },
    { text: 'ຍີ່ຫໍ້', value: 'brand' },
    { text: '', value: 'action' }
  ]

  products = []

  created () {
    this.fetchProducts()
  }

  async fetchProducts (): Promise<void> {
    try {
      this.isLoading = true
      const res = await axiosInstance.get('/manager/products')
      const data: any = res.data
      this.products = data.map(item => {
        const picture = item.pictures.length > 0
          ? `https://cdn.statically.io/img/sokxayallplus.s3.ap-southeast-1.amazonaws.com/w=200,f=auto,q=100/products/${item.pictures[0]}`
          : 'https://via.placeholder.com/200x80.png?text=No%20Image'

        return {
          id: item.id,
          picture: picture,
          name: item.name,
          brand: item.brand
        }
      })
      this.isLoading = false
    } catch (error) {
      console.error(error)
      this.isLoading = false
    }
  }
}
